import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Skeleton } from '@/components/ui/skeleton';
import { trpc } from '@/lib/trpc/trpc';
import { IconPlaneDeparture } from '@tabler/icons-react';
export default function Card_AircraftUpcomingBookings() {
  const getAllBookings = trpc.Booking.GetAllBookings.useQuery();
  return <Card data-sentry-element="Card" data-sentry-component="Card_AircraftUpcomingBookings" data-sentry-source-file="card_aircraftbookings.tsx">
      <CardHeader data-sentry-element="CardHeader" data-sentry-source-file="card_aircraftbookings.tsx">
        <CardTitle data-sentry-element="CardTitle" data-sentry-source-file="card_aircraftbookings.tsx">
          <div className='flex items-center gap-2'>
            <IconPlaneDeparture data-sentry-element="IconPlaneDeparture" data-sentry-source-file="card_aircraftbookings.tsx" />
            Aircraft Future Bookings
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent data-sentry-element="CardContent" data-sentry-source-file="card_aircraftbookings.tsx">
        {getAllBookings.data ? <div>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Date</TableHead>
                  <TableHead>User</TableHead>
                  <TableHead>Start Time</TableHead>
                  <TableHead>End Time</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {getAllBookings.data.map((booking, index) => {
              if (index > 3) {
                return;
              }
              return <TableRow key={index}>
                      <TableCell>{`${booking.bookingStart.getDate()}/${booking.bookingStart.getMonth() + 1}/${booking.bookingStart.getFullYear()}`}</TableCell>
                      <TableCell>{booking.bookingUserName}</TableCell>
                      <TableCell>
                        {booking.bookingStart.toLocaleTimeString()}
                      </TableCell>
                      <TableCell>
                        {booking.bookingEnd.toLocaleTimeString()}
                      </TableCell>
                    </TableRow>;
            })}
              </TableBody>
            </Table>
          </div> : <div className='flex flex-col space-y-3'>
            <Skeleton className='h-[125px] w-[250px] rounded-xl' />
            <div className='space-y-2'>
              <Skeleton className='h-4 w-[250px]' />
              <Skeleton className='h-4 w-[200px]' />
            </div>
          </div>}
      </CardContent>
    </Card>;
}