import Link from 'next/link';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Skeleton } from '@/components/ui/skeleton';
import { IconUserSearch } from '@tabler/icons-react';
import { trpc } from '@/lib/trpc/trpc';
import { Button } from '@/components/ui/button';
export default function Card_UserUpcomingBookings() {
  const userBookings = trpc.Booking.GetMyBookings.useQuery();
  return <Card data-sentry-element="Card" data-sentry-component="Card_UserUpcomingBookings" data-sentry-source-file="card_userbookings.tsx">
      <CardHeader data-sentry-element="CardHeader" data-sentry-source-file="card_userbookings.tsx">
        <CardTitle data-sentry-element="CardTitle" data-sentry-source-file="card_userbookings.tsx">
          <div className='flex items-center gap-2'>
            <IconUserSearch data-sentry-element="IconUserSearch" data-sentry-source-file="card_userbookings.tsx" />
            Your Future Bookings
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent data-sentry-element="CardContent" data-sentry-source-file="card_userbookings.tsx">
        {userBookings.data ? <div>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Date</TableHead>
                  <TableHead>Start Time</TableHead>
                  <TableHead>End Time</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {userBookings.data.map((booking, index) => {
              if (index > 3) {
                return;
              }
              return <TableRow key={index}>
                      <TableCell>{`${booking.bookingStart.getDate()}/${booking.bookingStart.getMonth() + 1}/${booking.bookingStart.getFullYear()}`}</TableCell>
                      <TableCell>
                        {booking.bookingStart.toLocaleTimeString()}
                      </TableCell>
                      <TableCell>
                        {booking.bookingEnd.toLocaleTimeString()}
                      </TableCell>
                    </TableRow>;
            })}
              </TableBody>
            </Table>
          </div> : <div className='flex flex-col space-y-3'>
            <Skeleton className='h-[125px] w-[250px] rounded-xl' />
            <div className='space-y-2'>
              <Skeleton className='h-4 w-[250px]' />
              <Skeleton className='h-4 w-[200px]' />
            </div>
          </div>}
      </CardContent>
      <CardFooter className='flex justify-end' data-sentry-element="CardFooter" data-sentry-source-file="card_userbookings.tsx">
        <div>
          <Link href='/calendar' className='' prefetch={false} data-sentry-element="Link" data-sentry-source-file="card_userbookings.tsx">
            <Button data-sentry-element="Button" data-sentry-source-file="card_userbookings.tsx">More Info</Button>
          </Link>
        </div>
      </CardFooter>
    </Card>;
}

// text-sm font-medium text-muted-foreground hover:text-foreground