import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { IconUserSearch } from '@tabler/icons-react';
import { trpc } from '@/lib/trpc/trpc';
export default function Card_AircraftBalance() {
  const aircraftBalance = trpc.Aircraft.GetAircraftCurrentBalance.useQuery();
  return <Card data-sentry-element="Card" data-sentry-component="Card_AircraftBalance" data-sentry-source-file="card_aircraftbalance.tsx">
      <CardHeader data-sentry-element="CardHeader" data-sentry-source-file="card_aircraftbalance.tsx">
        <CardTitle data-sentry-element="CardTitle" data-sentry-source-file="card_aircraftbalance.tsx">
          <div className='flex items-center gap-2'>
            <IconUserSearch data-sentry-element="IconUserSearch" data-sentry-source-file="card_aircraftbalance.tsx" />
            Aircraft Current Balance
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent data-sentry-element="CardContent" data-sentry-source-file="card_aircraftbalance.tsx">
        {aircraftBalance.data ? <div>
            <p>Balance: £{aircraftBalance.data.aircraftBalance.toFixed(2)}</p>
            <p>Bank Total: £{aircraftBalance.data.bankTotal.toFixed(2)}</p>
            <p>User Total: £{aircraftBalance.data.userTotal.toFixed(2)}</p>
          </div> : <Skeleton />}
      </CardContent>
    </Card>;
}