import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Table, TableBody, TableRow, TableCell } from '@/components/ui/table';
import { IconInfoCircle, IconNotebook } from '@tabler/icons-react';
import { trpc } from '@/lib/trpc/trpc';
import { Skeleton } from '@/components/ui/skeleton';
export default function Card_AircraftDocs() {
  const documents = trpc.Documents.GetAllDocuments.useQuery();
  return <Card data-sentry-element="Card" data-sentry-component="Card_AircraftDocs" data-sentry-source-file="card_aircraftdocs.tsx">
      <CardHeader data-sentry-element="CardHeader" data-sentry-source-file="card_aircraftdocs.tsx">
        <CardTitle data-sentry-element="CardTitle" data-sentry-source-file="card_aircraftdocs.tsx">
          <div className='flex items-center gap-2'>
            <IconInfoCircle data-sentry-element="IconInfoCircle" data-sentry-source-file="card_aircraftdocs.tsx" />
            Documentation
          </div>
        </CardTitle>
      </CardHeader>
      {documents.data ? <CardContent>
          <Table>
            <TableBody>
              {documents.data.map((document, index) => {
            return <TableRow key={index}>
                    <TableCell>
                      <IconNotebook size={20} />
                    </TableCell>
                    <TableCell>
                      <a target='_blank' href={document.url}>
                        {document.title}
                      </a>
                    </TableCell>
                  </TableRow>;
          })}
            </TableBody>
          </Table>
        </CardContent> : <div className='flex flex-col space-y-3'>
          <Skeleton className='h-[125px] w-[250px] rounded-xl' />
          <div className='space-y-2'>
            <Skeleton className='h-4 w-[250px]' />
            <Skeleton className='h-4 w-[200px]' />
          </div>
        </div>}
    </Card>;
}