// import Image from "next/image";
import { Inter as FontSans } from 'next/font/google';
import Card_AircraftUpcomingBookings from '@/components/elements/cards/card_aircraftbookings';
//import Card_AircraftStats from "@/components/elements/cards/card_aircraftstats";
import Card_UserUpcomingBookings from '@/components/elements/cards/card_userbookings';
import { cn } from '@/lib/utils';
import Card_AircraftDocs from '@/components/elements/cards/card_aircraftdocs';
import Card_UserBalance from '@/components/elements/cards/card_userbalance';
import Card_AircraftBalance from '@/components/elements/cards/card_aircraftbalance';
const fontSans = FontSans({
  subsets: ['latin'],
  variable: '--font-inter'
});
export default function Home() {
  return <div data-sentry-component="Home" data-sentry-source-file="index.tsx">
      <div className={cn('antialiased, font-inter', fontSans.variable)}>
        <div>
          <h1 className='scroll-m-20 p-5 text-4xl font-extrabold tracking-tight lg:text-5xl'>
            G-BAKW
          </h1>
        </div>
        <div className='xs:grid-cols-1 grid gap-3 xl:grid-cols-3'>
          <Card_AircraftDocs data-sentry-element="Card_AircraftDocs" data-sentry-source-file="index.tsx" />
          <Card_AircraftUpcomingBookings data-sentry-element="Card_AircraftUpcomingBookings" data-sentry-source-file="index.tsx" />
          <Card_UserUpcomingBookings data-sentry-element="Card_UserUpcomingBookings" data-sentry-source-file="index.tsx" />
          <Card_UserBalance data-sentry-element="Card_UserBalance" data-sentry-source-file="index.tsx" />
          <Card_AircraftBalance data-sentry-element="Card_AircraftBalance" data-sentry-source-file="index.tsx" />
        </div>
      </div>
    </div>;
}