import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { IconUserSearch } from '@tabler/icons-react';
import { trpc } from '@/lib/trpc/trpc';
export default function Card_UserBalance() {
  const userBalance = trpc.Finance.User.GetUserCurrentBalance.useQuery();
  return <Card data-sentry-element="Card" data-sentry-component="Card_UserBalance" data-sentry-source-file="card_userbalance.tsx">
      <CardHeader data-sentry-element="CardHeader" data-sentry-source-file="card_userbalance.tsx">
        <CardTitle data-sentry-element="CardTitle" data-sentry-source-file="card_userbalance.tsx">
          <div className='flex items-center gap-2'>
            <IconUserSearch data-sentry-element="IconUserSearch" data-sentry-source-file="card_userbalance.tsx" />
            Your Current Balance
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent data-sentry-element="CardContent" data-sentry-source-file="card_userbalance.tsx">
        {userBalance.data ? <div>
            <p>Balance: {userBalance.data.userBalance.toFixed(2)}</p>
            <p>
              Bank Transactions:{' '}
              {userBalance.data.userBankTransactionsTotal.toFixed(2)}
            </p>
            <p>
              User Transactions:{' '}
              {userBalance.data.userTransactionsTotal.toFixed(2)}
            </p>
          </div> : <Skeleton />}
      </CardContent>
    </Card>;
}